<!--
 * @Description: 产品新增
 * @Author: kecraft
 * @Date: 2024-01-03 14:46:42
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-31 16:29:10
 * @FilePath: /impact-iotos-console/src/views/product/productAdd.vue
-->

<template>
  <div class="product-add" id="home">
    <div class="breadcrumb-conatiner">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/product/product/list' }">产品管理</el-breadcrumb-item>
        <el-breadcrumb-item>{{ isEdit ? '编辑产品' : '创建产品' }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="add-conatiner" v-if="!isSuccess">
      <div class="form-conatiner">
        <div>
          <div class="item-title">
            <div class="item-icon">
              <img src="@/assets/product/icon1.png" alt="">
            </div>
            <div class="item-name">产品信息</div>
          </div>

          <!-- 产品信息 -->
          <el-form :model="form" :rules="rules" ref="formCustom1" label-position="left">
            <div class="form-top">
              <div>
                <el-form-item label="产品名称" prop="name" class="label-feather">
                  <el-input v-model="form.name" class="width512" placeholder="请输入产品名称" />
                </el-form-item>
              </div>
              <div>
                <el-form-item label="产品品类" prop="cid" class="label-feather">
                  <div class="width512">
                    <el-cascader v-model="form.cid" :options="cidTree" :props="optionProps" style="width: 100%;"
                      placeholder="请选择产品品类" filterable />
                  </div>
                </el-form-item>
              </div>
            </div>
            <div class="form-top">
              <div>
                <el-form-item label="型号" class="label-feather">
                  <el-input v-model="form.model" class="width512" placeholder="请输入型号" />
                </el-form-item>
              </div>
              <div>
                <el-form-item label="品牌" class="label-feather">
                  <el-input v-model="form.brand" class="width512" placeholder="请输入品牌" />
                </el-form-item>
              </div>
            </div>
          </el-form>
          <div class="item-title">
            <div class="item-icon">
              <img src="@/assets/product/icon2.png" alt="">
            </div>
            <div class="item-name">节点类型</div>
          </div>

          <!-- 节点类型 -->
          <el-form :model="form" :rules="rules" ref="formCustom2" label-position="left">
            <el-form-item prop="deviceType" class="label-feather">
              <template #label>
                <div class="tooltip-left">
                  节点类型
                  <el-tooltip class="box-item" effect="dark" content='<div>可在“文档中心” - “设备接入” - “创建产品” </div>
                  <div>中查看关于不同节点类型的详细说明</div>' placement="right" raw-content>
                    <el-icon style="padding-left: 20px;">
                      <QuestionFilled />
                    </el-icon>
                  </el-tooltip>
                </div>
              </template>
              <div class="radio-group-container">
                <!-- 节点类型 -->
                <div class="device-type-container">
                  <div class="device-type-item" v-for="(item, index) of deviceTypeCheckList" :key="index"
                    :class="form.deviceType === item.key ? 'checked' : isEdit ? 'not-selected' : ''"
                    @click="handleDeviceTypeChecked(item)">
                    <span class="next-tag-body">
                      <img class="select-img" :src="require(`@/assets/icon/type${index + 1}.png`)">
                      <span class="select-text">{{ item.label }}</span>
                    </span>
                  </div>
                </div>

              </div>
            </el-form-item>
            <el-form-item label="是否接入网关：" prop="isSwitchGateway" v-if="showIsSwitchGateway">
              <div class="radio-group-container">
                <div style="width:80px"></div>
                <div>
                  <el-radio-group v-model="form.isSwitchGateway" :disabled="isEdit">
                    <el-radio :label="false">否</el-radio>
                    <el-radio :label="true">是</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </el-form-item>
          </el-form>

          <!-- 联网与数据 -->
          <div class="item-title">
            <div class="item-icon">
              <img src="@/assets/product/icon3.png" alt="">
            </div>
            <div class="item-name">联网与数据</div>
          </div>
          <el-form :model="form" :rules="rules" ref="formCustom" label-position="left">
            <el-form-item label="联网方式" prop="deviceLinkType" class="label-feather">
              <div class="radio-group-container">
                <!-- 联网方式 -->
                <div>
                  <el-radio-group v-model="form.deviceLinkType" @click="handleDeviceLinkTypeChanged($event)"
                    :disabled="isEdit">
                    <el-radio label="WIFI" name="WIFI">Wi-Fi</el-radio>
                    <el-radio label="CELLUAR" name="CELLUAR">2/3/4/5G</el-radio>
                    <el-radio label="NBIOT" name="NBIOT" :disabled="true">NB-IoT</el-radio>
                    <el-radio label="LORA" name="LORA" :disabled="true">LoRa</el-radio>
                    <el-radio label="ETH" name="ETH">以太网(有线)</el-radio>
                    <el-radio label="OTHER" name="OTHER" :disabled="true">其他</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="交互协议" prop="transferType" class="label-feather">
              <div class="radio-group-container">
                <div>
                  <el-radio-group v-model="form.transferType" :disabled="isEdit">
                    <el-radio v-for="(item, index) of transferTypeList" :key="index" :label="item.key"
                      :disabled="item.disabled">{{ item.label
                      }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="dataFormat" class="label-feather">
              <template #label>
                <div class="tooltip-left">
                  数据格式
                  <el-tooltip class="box-item" effect="dark" content='<div>DLink:是为开发者提供的设备与云端之间数据交换的标准协议，采用json格式 </div>
                  <a href="http://10.10.49.179:3824/web/#/632016434/191201016" style="color:#409eff;text-decoration: underline;">（格式详情可以在“文档中心”-“设备管理”-“DLink”中查看）</a>
                  <div>自定义：统指非DLink的数据交换协议，如ModBus、十六进制等，需要配置“数据解析”或“软件边缘网关：实现接入。</div>' placement="right" raw-content>
                    <el-icon style="padding-left: 20px;">
                      <QuestionFilled />
                    </el-icon>
                  </el-tooltip>
                </div>
              </template>
              <div>
                <el-radio-group v-model="form.dataFormat" @click="handleDataFormatChange($event)" :disabled="isEdit">
                  <el-radio label="DLINK" name="DLINK">DLink</el-radio>
                  <el-radio label="CUSTOM" name="CUSTOM">自定义</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item prop="dataCheckMode" class="label-feather">
              <template #label>
                <div class="tooltip-left">
                  功能参数校验模式
                  <el-tooltip class="box-item" effect="dark" content='<div>严格模式：协议参数完成按照协议规定上报或者下发，不能多也不能少。 </div>
                  <div>宽松模式：允许上报或者下发的参数不完成匹配协议中命令中的参数，可以多，可以少。</div>' placement="right" raw-content>
                    <el-icon style="padding-left: 20px;">
                      <QuestionFilled />
                    </el-icon>
                  </el-tooltip>
                </div>
              </template>
              <div>
                <el-radio-group v-model="form.dataCheckMode" :disabled="isEdit">
                  <el-radio label="STRICT">严格模式</el-radio>
                  <el-radio label="LOOSE">宽松模式</el-radio>
                </el-radio-group>
              </div>
              <!-- </div> -->
            </el-form-item>
            <el-form-item label="设备登录安全校验" prop="loginCheck" v-if="showDynamicRegister" class="label-feather">
              <div class="radio-group-container">
                <div>
                  <el-radio-group v-model="form.loginCheck">
                    <el-radio :label="false">关闭</el-radio>
                    <el-radio :label="true">开启</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="设备动态注册" prop="dynamicRegister" v-if="showRegister" class="label-feather">
              <div class="radio-group-container">
                <div>
                  <el-radio-group v-model="form.dynamicRegister" :disabled="isEdit">
                    <el-radio :label="false">不允许</el-radio>
                    <el-radio :label="true">允许</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="设备动态注册安全校验" prop="registerCheck" v-if="showRegister" class="label-feather">
              <div class="radio-group-container">
                <div>
                  <el-radio-group v-model="form.registerCheck" :disabled="isEdit">
                    <el-radio :label="false">关闭</el-radio>
                    <el-radio :label="true">开启</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="footer-container">
        <div class="btn-conatiner">
          <div class="cancel-btn" @click="cancel">取消</div>
          <div class="add-btn" @click="create">{{ isEdit ? '确认' : '创建' }}</div>
        </div>
      </div>
    </div>


    <div class="success-container" v-else>
      <div class="success-box">
        <img src="@/assets/drive/success1.png">
        创建成功
      </div>
      <div class="title-top">您已经成功完成创建产品，接下来您可以：</div>
      <div class="success-item">
        <div class="item-right">
          <div class="success-title">添加设备</div>
          <div class="success-text">设备归属某个产品下的具体设备。物联网平台为设备颁发产品内唯一的证书。设备可以直接连接物联网平台，也可以作为子设备通过网关连接物联网平台。</div>

        </div>
        <div class="btn-right-groups" @click="gotoDevice">
          前往添加
        </div>
      </div>
      <div class="success-item">
        <div class="item-right">
          <div class="success-title">功能定义</div>
          <div class="success-text">
            物联网平台支持为产品定义物模型，将实际产品抽象成由属性、服务、事件所组成的数据模型，便于云端管理和数据交互，产品创建完成后，您可以为它定义物模型，产品下的设备将自动继承物模型内容</div>
        </div>
        <div class="btn-right-groups" @click="handleFeature">
          前往功能定义
        </div>
      </div>
      <div class="success-btns">
        <div class="btn-blue" @click="handleDetails">
          查看产品详情
        </div>
        <div class="btn-normal" @click="handleBack">
          返回产品列表
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ElMessage } from "element-plus";
import api from '@/api/api'
import { QuestionFilled } from '@element-plus/icons-vue';
import { reactive, ref, onMounted } from 'vue';
import { useRouter, useRoute } from "vue-router";
import { convertToTree } from "@/hooks/common";
import { useStore } from "vuex";
const store = useStore();
const isSuccess = ref(false);
const showRegister = ref(false);
const optionProps = {
  value: "id",
  label: "name",
  children: "children",
};
const deviceTypeCheckList = ref([
  {
    label: "直连设备",
    key: "GENERAL",
  },
  {
    label: "终端子设备",
    key: "TERMINAL",
  },
  {
    label: "网关设备",
    key: "GATEWAY",
  },
  {
    label: "中继设备",
    key: "SWITCH",
  },
])
const cidTree = ref([]);
const route = useRoute();
const router = useRouter();
const form = reactive({
  name: "", // 产品名称
  cid: "", // 产品品类
  model: "", // 型号
  brand: "", // 品牌
  isSwitchGateway: "", // 是否接入网关
  deviceType: "", // 节点类型
  deviceLinkType: "", // 联网方式
  transferType: "", // 交互协议
  dataFormat: "", // 数据格式
  loginCheck: false, // 设备登录安全校验
  dataCheckMode: "STRICT", // 功能参数校验模式  STRICT:严格模式(默认),LOOSE:宽松模式,LESS:可以缺失,MORE:可以更多
  dynamicRegister: false,
  registerCheck: false,
})
const showIsSwitchGateway = ref(false); // 是否展示接入网关
const showDynamicRegister = ref(false); // 是否展示设备登录安全校验
const formCustom1 = ref(null); // 产品信息校验
const formCustom2 = ref(null); // 节点类型校验
const formCustom = ref(null); // 联网与数据校验
const successInfo = ref(null);
const rules = {
  name: [
    { required: true, message: "产品名称不能为空", trigger: "change" },
    {
      min: 2,
      max: 24,
      message: '长度在 2 到 24 个字符',
      trigger: 'blur'
    }
  ],
  cid: [
    { required: true, message: "产品品类不能为空", trigger: "change" },
  ],
  deviceType: [
    { required: true, message: "节点类型不能为空", trigger: "blur" },
  ],
  deviceLinkType: [
    { required: true, message: "联网方式不能为空", trigger: "blur" },
  ],
  transferType: [
    { required: true, message: "交互协议不能为空", trigger: "blur" },
  ],
  dataFormat: [
    { required: true, message: "数据格式不能为空", trigger: "blur" },
  ],
  dataCheckMode: [
    { required: true, message: "功能模式校验不能为空", trigger: "blur" },
  ],
  loginCheck: [
    { required: true, message: "设备登录安全校验不能为空", trigger: "blur" },
  ],
  dynamicRegister: [
    { required: true, message: "设备动态注册不能为空", trigger: "blur" },
  ],
  registerCheck: [
    { required: true, message: "设备动态注册安全校验不能为空", trigger: "blur" },
  ],
  isSwitchGateway: [
    { required: true, message: "是否接口网关不能为空", trigger: "blur" },
  ],
}
const transferTypeList = ref([
  {
    key: "HTTP",
    label: "HTTP",
    disabled: false,
  },
  {
    key: "MQTT",
    label: "MQTT",
    disabled: false,
  },
  {
    key: "COAP",
    label: "CoAP",
    disabled: true,
  },
  {
    key: "LWM2M",
    label: "LwM2M",
    disabled: true,
  },
  {
    key: "NB_OPERATOR",
    label: "运营商转发",
    disabled: true,
  },
  {
    key: "LORA",
    label: "LoRaWAN",
    disabled: true,
  },
  {
    key: "OTHER",
    label: "其他",
    disabled: true,
  },
]);
const isEdit = ref(false);
const productKey = ref(null);
const handleDeviceTypeChecked = (item) => {
  if (isEdit.value) return;
  if (form.deviceType === item.key) return;
  if (item.key === "TERMINAL" || item.key === "SWITCH") {
    showRegister.value = true;
    form.registerCheck = form.dynamicRegister = false;
  } else {
    showRegister.value = false;
  }
  form.deviceType = item.key
}
onMounted(() => {
  /**
   * 通过是否有PK判断是否是编辑
   */
  const { pk } = route.query;
  if (pk) {
    isEdit.value = true;
    productKey.value = pk
    getProductInfo();
  }
});

const getTree = () => {
  api.getAllCategory().then(res => {
    if (res.code === "0") {
      const list = res.res.data;
      const tree = convertToTree(list)
      cidTree.value = tree;
    }
  })
}
getTree();

const cancel = () => {
  router.go(-1);
}
/**
 * 根据pk获取产品信息
 */
const getProductInfo = () => {
  api.getProductInfoByPK(productKey.value).then(res => {
    if (res.code === "0" && res.res.data) {
      const { name, categories, model, brand, deviceLinkType, deviceType, transferType, dataFormat, loginCheck, dataCheckMode, dynamicRegister
        , registerCheck } = res.res.data;
      dealDeviceLinkTypeList(deviceLinkType)
      if (dataFormat === "DLINK") showDynamicRegister.value = true;
      if (deviceType === "TERMINAL" || deviceType === "SWITCH") showRegister.value = true;
      form.deviceType = deviceType;
      const cids = []
      categories.forEach(item => {
        cids.push(item.id)
      })
      form.name = name;
      form.cid = cids;
      form.model = model;
      form.brand = brand;
      form.dataFormat = dataFormat;
      form.transferType = transferType;
      form.deviceLinkType = deviceLinkType;
      form.loginCheck = loginCheck;
      form.registerCheck = registerCheck;
      form.dynamicRegister = dynamicRegister;
      form.dataCheckMode = dataCheckMode;
    }
  })
}
/**
 * 根据不同的联网方式展示不同的交互协议
 * @param {*} event 
 */
const handleDeviceLinkTypeChanged = (event) => {
  if (isEdit.value) return;
  const name = event.target.name;
  if (form.deviceLinkType === name) return;
  form.transferType = "";
  dealDeviceLinkTypeList(name)

}

const dealDeviceLinkTypeList = (name) => {
  if (name === "WIFI" || name === "CELLUAR" || name === "ETH" || name === "OTHER") {
    transferTypeList.value = [
      {
        key: "HTTP",
        label: "HTTP",
      },
      {
        key: "MQTT",
        label: "MQTT",
      },
      // {
      //   key: "OTHER",
      //   label: "其他",
      // },
    ]
  } else if (name === "LORA") {
    transferTypeList.value = [
      {
        key: "LORA",
        label: "LoRaWAN",
      },
    ]
  } else if (name === "NBIOT") {
    transferTypeList.value = [
      {
        key: "COAP",
        label: "CoAP",
      },
      {
        key: "LWM2M",
        label: "LwM2M",
      },
      {
        key: "NB_OPERATOR",
        label: "运营商转发",
      },
    ]
  }
}

/**
 * 当数据格式为DLink的时候需要展示设备登录安全校验
 * @param {*} event 
 */
const handleDataFormatChange = (event) => {
  if (isEdit.value) return;
  const name = event.target.name
  if (form.dataFormat === name) return;
  form.loginCheck = false;
  if (name === "DLINK") {
    showDynamicRegister.value = true;
  } else if (name === "CUSTOM") {
    showDynamicRegister.value = false;
  }
}

const create = () => {
  formCustom.value.validate((valid) => {
    if (valid) {
      check1()
    } else {
      return false;
    }
  });
}

const check1 = () => {
  formCustom1.value.validate((valid) => {
    if (valid) {
      check2()
    } else {
      return false;
    }
  });
}

const check2 = () => {
  formCustom2.value.validate((valid) => {
    if (valid) {
      if (isEdit.value) {
        editProduct();
      } else {
        createProduct()
      }

    } else {
      return false;
    }
  });
}

/**
 * 创建产品
 */
const createProduct = () => {
  const cids = form.cid;
  form.cid = cids[cids.length - 1]
  api.productAdd(form).then(res => {
    if (res.code === "0") {
      ElMessage.success("创建成功!")
      successInfo.value = res.res.data;
      isSuccess.value = true;
    }
  })
}

/**
 * 编辑产品
 */
const editProduct = () => {
  const cids = form.cid;
  form.cid = cids[cids.length - 1]
  api.productEdit(form, productKey.value).then(res => {
    if (res.code === "0") {
      ElMessage.success("编辑成功!")
      successInfo.value = res.res.data;
      isSuccess.value = true;
      // router.push("/product/product/list")
    }
  })
}

const handleBack = () => {
  router.push("/product/product/list")
}

const handleDetails = () => {
  if (!successInfo.value) return;
  const { name, pk } = successInfo.value
  if (pk && name) {
    sessionStorage.removeItem("handleTabsSelect")
    router.push({
      path: "/product/product/details",
      query: { name, pk }
    })
  }
}

const handleFeature = () => {
  if (!successInfo.value) return;
  const { name, pk } = successInfo.value
  if (pk && name) {
    sessionStorage.setItem("handleTabsSelect", "功能定义")
    router.push({
      path: "/product/product/details",
      query: { name, pk }
    })
  }
}

const gotoDevice = () => {
  if (!successInfo.value) return;
  const { pk } = successInfo.value
  if (pk) {
    store.dispatch("vuexTest/changePkValue", pk);
    router.push({
      path: "/product/device/list",
    })
  }

}
</script>

<style lang="less" scoped>
.footer-container {
  border-top: 1px solid #E9ECF0;
  margin-top: 20px;
}

.tooltip-left {
  display: flex;
  align-items: center;
}

.form-width {
  widows: 350px;
}

.breadcrumb-conatiner {
  box-sizing: border-box;
  padding: 20px 28px;
  border-bottom: 1px solid #E9ECF0;
}

.width512 {
  width: 350px;
}

.width5121 {
  width: 535px;
}

.not-selected {
  cursor: not-allowed !important;
}

.device-type-container {
  display: flex;
  align-items: center;

  .checked {
    color: #0070cc;
    border-color: #0070cc;
    background-color: transparent;
  }

  .checked::before {
    right: -14px;
    top: -14px;
    width: 28px;
    height: 28px;
    position: absolute;
    content: "";
    -webkit-font-smoothing: antialiased;
    background-color: #0070cc;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.checked::after {
  width: 12px;
  height: 12px;
  right: 3px;
  top: -2px;
  position: absolute;
  font-family: NextIcon;
  -webkit-font-smoothing: antialiased;
  content: url("../../assets/icon/checked.png");
  -webkit-transform: scale(.3);
  -ms-transform: scale(.3);
  transform: scale(.3);
  color: #fff;
}

.device-type-item {
  overflow: hidden;
  width: 182px;
  height: 90px;
  margin-right: 10px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  border: 1px solid #d3d5d5;
  background: #f9f9f9;
  position: relative;

  .next-tag-body {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .select-img {
      width: 30px;
      height: 30px
    }

    .select-text {
      font-family: PingFangSC-Regular;
      padding-left: 13px;
      font-size: 16px;
      text-align: left;
      word-break: break-all;
      white-space: break-spaces;
    }
  }
}

.device-type-item:hover {
  color: #0070cc;
  border-color: #0070cc;
  background-color: transparent;
}

.product-add {
  box-sizing: border-box;
  // padding: 20px;
}

.add-conatiner {
  .form-conatiner {
    box-sizing: border-box;
    padding-top: 19px;
    padding-left: 50px;

    .line {
      width: 100%;
      height: 1px;
      background: #eee;
    }

    &>div {
      box-sizing: border-box;
      // padding: 10px 30px 30px 30px;
      background: #ffffff;

      .item-title {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-bottom: 20px;

        .item-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .item-name {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    .radio-group-container {
      display: flex;
      align-items: center;
    }

    .form-top {
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      &>div:first-child {
        margin-right: 90px;
      }
    }
  }
}

.btn-conatiner {
  float: right;
  margin-top: 14px;
  margin-right: 50px;
  display: flex;
  align-items: center;

  .add-btn {
    margin-left: 20px;
    border-radius: 4px;
    width: 136px;
    cursor: pointer;
    text-align: center;
    background: #367CC5;
    color: #ffffff;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
  }

  .cancel-btn {
    box-sizing: border-box;
    border-radius: 4px;
    width: 136px;
    cursor: pointer;
    text-align: center;
    color: #333333;
    border: 1px solid #B7BBC0;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
  }
}

.success-container {
  box-sizing: border-box;
  padding: 0 110px;
  margin-top: 64px;

  .success-box {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 24px;
    font-weight: bold;

    img {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }
  }

  .title-top {
    margin-top: 100px;
    color: #333333;
    line-height: 22px;
  }

  .success-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    background: #F9FCFD;
    border: 1px solid #E9ECF0;
    margin-top: 30px;
    box-sizing: border-box;
    padding: 30px;

    .success-title {
      color: #333333;
      line-height: 25px;
      margin-bottom: 5px;
    }

    .success-text {
      color: #919191;
      font-size: 14px;
      font-weight: lighter;
      line-height: 20px;
    }

    .item-right {
      flex: 1;
      box-sizing: border-box;
      margin-right: 180px;
    }
  }

  .success-btns {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    &>div:first-child {
      margin-right: 10px;
    }
  }
}

.btn-blue {
  cursor: pointer;
  width: 136px;
  height: 36px;
  background-color: #367CC5;
  box-sizing: border-box;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  color: #FFFFFF;
  border-radius: 4px;
}

.btn-normal {
  cursor: pointer;
  width: 136px;
  height: 36px;
  box-sizing: border-box;
  border: 1px solid #B7BBC0;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  color: #333333;
  border-radius: 4px;
}

.btn-right-groups {
  cursor: pointer;
  width: 130px;
  height: 36px;
  box-sizing: border-box;
  border: 1px solid #367CC5;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  color: #367CC5;
  border-radius: 4px;
}
</style>

<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 185px;
  }
}
</style>